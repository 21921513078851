import React, { useEffect, useRef, useState } from 'react';
import exporticon from "../../images/exportpdf.svg";
import editicon from "../../images/edit-icon.svg";
import deleteicon from "../../images/deleteicon.svg";
import classes from "./ManagerOptions.module.css";
import { exportTableToPDF } from '../../utils/exportTableToPdf';
import exportToExcel from '../reusable/exportToExcel';
import AddEmployeePopup from '../admin/events comps/AddEmployeePopup';
import CancelPopup from '../employee/CancelPopup';

const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Employees",
    accessor: "name",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Phone no.",
    accessor: "phoneNo",
  },
  {
    Header: "Transportation",
    accessor: "transportation",
  },
  {
    Header : "Event Time",
    accessor : "eventTime"
  },
  {
    Header: "",
    accessor: "",
  },
];

function ManagerOptions({ totalData,data,update,setUpdate }) {
  const [optionsOpen, setOptionsOpen] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Store selected employee for editing
  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setOptionsOpen(false);
    }
  };
  const boxRef = useRef(null);
  useEffect(() => {

    document.addEventListener('click', handleBackdropClick, true);
    return () => {
      document.removeEventListener('click', handleBackdropClick, true);

    }
  }, []);
  const handleExportPDF = () => {
    // console.log(totalData);
    const dataForPDF = totalData.map(row => columns.map(col => row[col.accessor]));
    exportTableToPDF(columns.map(col => col.Header), dataForPDF, 'Event Details');
  };
  const handleExportToExcel = () => {
    console.log(data);
    if (totalData && totalData.length > 0) {
        // Map over totalData to create an array of filtered objects
        const filteredData = totalData.map(({ id, name, phoneNo, court, transportationStatus, status }) => ({
            id,
            name,
            phoneNo,
            court,
            transportationStatus,
            status
        }));
        
        // Call exportToExcel with the filtered data
        exportToExcel(filteredData, "registeredEmployee.xlsx");
    } else {
        // Show an error toast if totalData is empty or undefined
        console.error("No data available to export.");
    }
};

  const handleEditOpen = () => {
    setSelectedEmployee(data);
    setEditDialogOpen(true);
    console.log(totalData);
    
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteDialogOpen(true);
  }
  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  }

  return (
    <>
      {optionsOpen && <div className={classes.boxcontainer} ref={boxRef}>
        <div className={classes.box}>
          <div className={classes.btngrp}>
            <span className={classes.btn}>
              <img
                src={exporticon}
                alt="View details icon"
                className={classes.icon}
              />
              <p className={classes.two} onClick={handleExportToExcel}>Export Pdf</p>
            </span>
            <span className={classes.btn}>
              <img
                src={editicon}
                alt="Edit icon"
                className={classes.icon}
              />
              <p className={classes.two} onClick={handleEditOpen}>Edit</p>
            </span>
            <span className={classes.btn}>
              <img
                src={deleteicon}
                alt="Edit icon"
                className={classes.icon}
              />
              <p className={classes.two} onClick={handleDeleteOpen}>Delete</p>
            </span>
          </div>
        </div>
      </div>}
      <AddEmployeePopup
        open={editDialogOpen}
        handleClose={handleEditClose}
        eventid={data?.eventId}
        courtData={data?.courtData}
        update={update}
        setUpdate={setUpdate}
        employeeData={selectedEmployee} // Pass the selected employee data
        editMode={true} // Set edit mode
      />
      <CancelPopup
        open={deleteDialogOpen}
        handleClose={handleDeleteClose}
        id={data.eventid}
        empID={data.employeeID}
        title={"Delete"}
        update={update}
        setUpdate={setUpdate}
      />
    </>
  );
}

export default ManagerOptions;