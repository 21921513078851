import React from 'react';
import noDataImg from './../../images/nodata.png';

function NoData({title,subtitle}) {
    return (
        <div className='noData'>
        <img src={noDataImg} alt="" />
        <b>{title}</b>
        <p>{subtitle}</p>
      </div>
    );
}

export default NoData;