
import LinearProgress from '@mui/joy/LinearProgress';
import { styled } from '@mui/material';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    // Track color
    backgroundColor: '#d3d3d3',
    color:'#F87B83',
    margin:'2rem 2.4rem',
    // Progress bar color
    '& .MuiLinearProgress-bar': {
      color: '#DB545D'
    },
  }));
  
  export default function Loader() {
    return <CustomLinearProgress />;
  }
