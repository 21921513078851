import React, { useEffect, useRef, useState } from "react";
import assignworkersicon from "../../../images/assignworkersicon.svg";
import eyeicon from "../../../images/eyeicon.svg";
import editicon from "../../../images/editicon.svg";
import deleteicon from "../../../images/deleteicon.svg"
import classes from "./EventOptions.module.css";
import CustomDialog from "../../reusable/CustomDialog";
import { Link, useNavigate } from "react-router-dom";
import Deletepopup from "./Deletepopup";

function EventOptions({id}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [optionsOpen,setOptionsOpen]=useState(true);
  const navigate=useNavigate();
  const boxRef = useRef(null);
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const detailsClickHandler=(e)=>{
    navigate(`/viewevent/${id}`);
  }

  const editClickHandler=(e)=>{
    navigate(`/editevent/${id}`);
  }
  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setOptionsOpen(false);
    }
  };
  useEffect(() => {
    if(!dialogOpen){
    document.addEventListener('click', handleBackdropClick, true);
    return () => {
      document.removeEventListener('click', handleBackdropClick, true);
    };
    
  }
  if(dialogOpen){
    setOptionsOpen(false);
  }
  }, [dialogOpen]);
  return <>
  
    {optionsOpen && 
    <div className={classes.boxcontainer} ref={boxRef}>
      <div className={classes.box}>
        <div className={classes.btngrp}>
            <div className={classes.btn} onClick={detailsClickHandler}>
                <img src={assignworkersicon} className={classes.icon}/>
                <p className={classes.one}>View details</p>
            </div>
            <div  className={classes.btn} onClick={editClickHandler}>
                <img src={editicon} className={classes.icon}/>
                <p className={classes.two}>Edit</p>
            </div>
            <a href="#" className={classes.btn} onClick={handleOpen}>
                <img src={deleteicon} className={classes.icon} />
                <p className={classes.three}>Delete</p>
            </a>
        </div>
    </div>
    
    </div>}
    <Deletepopup open={dialogOpen} handleClose={handleClose} id={id}/>
    </>
}

export default EventOptions;
