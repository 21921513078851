import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import classes from './MyFormControl.module.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormLabel } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    width: "100%",
    margin: "0",
    '& .MuiOutlinedInput-root': {
      borderRadius: "1.2rem",
      height: "4rem",
      '&:hover fieldset': {
        borderColor: '#DB545D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#DB545D',
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: '#DB545D',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0,0,0,0.1)',
  },
  }));
  
  
  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "1.4rem",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontStyle: "normal",
    color: "#00000040",
  
    "&.Mui-selected": {
      color: "#DB545D",
      backgroundColor: "rgba(248, 123, 131, 0.2) !important",

      "b": {
        color: "#DB545D",
      },

      "&:hover": {
        backgroundColor: "rgba(248, 123, 131, 0.2) !important",
      },
    },
    "&.Mui-hover":{
      backgroundColor: "#DB545D",
    }
  }));
  const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: "1.4rem",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#00000080",
  }));
  
  const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
      fontSize: "1.4rem",
      fontFamily: "Poppins",
      fontWeight: "500",
      lineHeight:"2.4",
      color: "#000"
    }
}));


export default function MultipleSelect({title,data,value,setValue}) {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      
        typeof value === 'string' ? value.split(',') : value,
      )
    ;
  };

  return (
    <>
      <CustomFormControl>
      <CustomFormLabel id="event-type-label" className={classes.labell}>
                {title}
              </CustomFormLabel>
        <CustomSelect
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={value}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => { if (selected.length === 0) { return <em>Select</em>; } return selected.join(', '); }}
          MenuProps={MenuProps}
        >
          <CustomMenuItem disabled value=""><em>Select</em></CustomMenuItem>
          {data?.map((courtName,index) => (
            <CustomMenuItem
              key={index}
              value={courtName}
            >
              {courtName}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </>
  );
}
