import React from "react";
import classes from "./ManagerHeader.module.css";
import ManagerList from "./ManagerList";

function ManagerHeader(props) {
  return (
    <>
      <div className={classes.tabheader}>
        <div className={classes.eventcal}>
          <p className={classes.active}>Manager</p>
        </div>
      </div>
      <ManagerList/>
    </>
  );
}

export default ManagerHeader;
