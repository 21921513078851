import React, { useEffect, useState } from "react";
import DialogCard from "../../reusable/DialogCard";
import CustomCheckbox from "../../reusable/CustomCheckbox";
import classes from "./AddEmployeePopup.module.css";
import MyFormControl from "../../reusable/MyFormControl";
import { DialogContentText } from "@mui/material";
import { getEmployees, register, updateRegistration } from "../../../utils/ApiFunctions";

function AddEmployeePopup({
  open,
  handleClose,
  eventid,
  courtData,
  update,
  setUpdate,
  employeeData,
  editMode=false,
}) {
  // const [employees, setEmployees] = useState(employeeData?.name || ""); // Pre-fill employee name if editing
  const [employees, setEmployees] = useState("");
  const [empData, setEmpData] = useState("");
  const [registerEmployee, setRegisterEmployee] = useState("");
  const [court, setCourt] = useState("");
  // const [court, setCourt] = useState(employeeData?.court || ""); // Pre-fill court if editing
  // const [needTransportation, setNeedTransportation] = useState(employeeData?.transportation || false); // Pre-fill transportation if editing
  const [needTransportation, setNeedTransportation] = useState(false);

  useEffect(() => {
    if (employeeData) {
      console.log(employeeData);
      setEmployees(employeeData.name || "");
      setCourt(employeeData.court || "");
      setNeedTransportation(
        employeeData.transportation == "Requested" ? true : false
      );
    }
  }, [employeeData]);

  const getEmps = async () => {
    const resp = await getEmployees();
    if (resp) {
      const temp = resp.data.employees.map((e) => ({
        id: e._id,
        name: e.name,
      }));
      setEmpData(temp);
      setRegisterEmployee(resp.data.employees.map((e) => e.name));
    }
  };
  useEffect(() => {
    getEmps();
  }, []);
  // const addEmployeeHandler=async()=>{
  //   const resp=await register({
  //     employee:empData?.find((emp) => emp.name === employees).id,
  //     court:courtData?.find((c)=>c.name===court),
  //     event:eventid,
  //     // transportation:false,
  //     // transportationStatus:false,
  //     registrationStatus:true
  //   });
  //   if(resp){
  //     setUpdate(!update);
  //     handleClose();
  //     setEmployees("");
  //     setCourt("");
  //   }
  // }

  const addEmployeeHandler = async () => {
    const selectedEmployee = empData?.find((emp) => emp.name === employees);
    const employeeForm = {
      employee: selectedEmployee.id,
      name: selectedEmployee.name,
      court: courtData?.find((c) => c.name === court),
      event: eventid,
      registrationStatus: true,
      transportation: needTransportation,
      transportationStatus : needTransportation ? true : false,
    };

    let resp;
    if (editMode) {
      // Update registration if in edit mode
      resp = await updateRegistration(employeeData._id, employeeForm);
    } else {
      // Register new employee
      resp = await register(employeeForm);
    }

    if (resp) {
      setUpdate(!update);
      handleClose();
      setEmployees("");
      setCourt("");
      setNeedTransportation(false); // Reset the checkbox value
    }
  };

  return (
    <DialogCard
      open={open}
      handleClose={handleClose}
      title={editMode ? "Edit Employee" : "Add Employee"}
      content={
        <>
          <div className={classes.dropdowncontainer}>
            <div className={classes.marginBottomFormControl}>
              <MyFormControl
                title={"Employee"}
                data={registerEmployee}
                setValue={setEmployees}
                value={employees}
                disabled={editMode} // Disable the court selection in edit mode
              />
            </div>

            <div>
              <MyFormControl
                title={"Court"}
                data={courtData?.map((c) => c?.name)}
                setValue={setCourt}
                value={court}
              />
            </div>
            <CustomCheckbox
              text="Need Transportation" // Label for the checkbox
              setCheck={setNeedTransportation}
              checked={needTransportation} // Set state when checkbox is changed
            />
            <DialogContentText id="alert-dialog-description">
              <p className={classes.maincontent}>
                <span>Note:</span> On adding employee you will select it as a
                worker for this event
              </p>
            </DialogContentText>
          </div>
        </>
      }
      btnOne={"Save"}
      btnTwo={"Cancel"}
      btnOneAction={addEmployeeHandler}
    />
  );
}

export default AddEmployeePopup;
