import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import morevert from "../../images/morevert.svg";
import RegisterPopup from "../employee/RegisterPopup";
import registericon from "../../images/registericonpink.svg";
import cancelregicon from "../../images/cancelregpink.svg";
import CustomRegisterCancelOption from "./CustomRegisterCancelOption";
import CancelPopup from "../employee/CancelPopup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#DB545D1A",
    backgroundColor: "#FDEEEF",
    color: "#DB545D",
    fontWeight: "bold",
    fontSize: "1.6rem",
    fontFamily: "Poppins",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.6rem",
    fontFamily: "Poppins",
    overflow: "visible",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    borderBottom: "none",
  },
}));

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
  overflow: "visible",
  borderRadius: "1.6rem",
  [theme.breakpoints.down("md")]: { overflowY: "auto" }
  // height:"65vh"
}));

const CustomTable = ({ columns, data, OptionsComponent, stateProp ,update,setUpdate, responsive,showRegisterCancelOption = true}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showCellOptions, setShowCellOptions] = useState({});

  const handleCellClick = (rowIndex) => {
    setShowCellOptions((prev) => (prev === rowIndex ? null : rowIndex));
  };

  return (
    <CustomTableContainer component={Paper}>
      <Table className={responsive} sx={{ maxWidth: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns?.map((column, colIndex) => (
              <StyledTableCell
                key={column.Header}
                align={column.Header === "Participants" ? "center" : "left"}
              >
                {column.Header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {columns?.map((column, colIndex) => (
                <StyledTableCell
                  key={colIndex}
                  align={column.Header === "Participants" ? "center" : "left"}
                  style={{
                    fontWeight: colIndex === 0 ? 600 : "normal",
                    // backgroundColor:row[column.accessor]==="Approved"?"#25B9181A":row[column.accessor]==="Declined"?"#DB545D1A":row[column.accessor]==="Pending"?"#DC7F291A":"",
                    color:
                      row[column.accessor] === "Approved" ||
                      row[column.accessor] === "Paid"
                        ? "#25B918"
                        : row[column.accessor] === "Declined"||row[column.accessor]==="Unpaid"
                        ? "#DB545D"
                        : row[column.accessor] === "Pending"
                        ? "#DC7F29"
                        : "",
                    // borderRadius:column.accessor==="status"?"0.8rem":"",
                  }}
                >
                  {colIndex === columns.length - 1 &&
                    row[column.accessor] !== "register" &&
                    row[column.accessor] !== "cancelevent" && (
                      <>
                        <img
                          className="cursor-pointer"
                          src={morevert}
                          alt=""
                          onClick={() => handleCellClick(rowIndex)}
                        />
                        {showCellOptions === rowIndex && OptionsComponent && (
                          <OptionsComponent
                            id={data[rowIndex]._id}
                            phoneNo={data[rowIndex]?.phoneNo || null}
                            data={data[rowIndex]}
                            // optionProps={
                            //   optionProps ? optionProps[rowIndex] : null
                            // }
                            stateProp={stateProp}
                            totalData={data}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        )}
                      </>
                    )}
                  {colIndex !== columns.length - 1 && row[column.accessor]}
                  {colIndex === columns.length - 1 && showRegisterCancelOption &&
                    row[column.accessor] === "register" && (
                      <CustomRegisterCancelOption
                        img={registericon}
                        PopupComponent={RegisterPopup}
                        text={"Register"}
                        id={data[rowIndex]._id}
                        update={update}
                        setUpdate={setUpdate}
                        courts={data[rowIndex].court}
                      />
                    )}
                  {colIndex === columns.length - 1 && showRegisterCancelOption &&
                    row[column.accessor] === "cancelevent" && (
                      <CustomRegisterCancelOption
                        img={cancelregicon}
                        PopupComponent={CancelPopup}
                        text={"Cancel"}
                        id={data[rowIndex]._id}
                        update={update}
                        setUpdate={setUpdate}
                      />
                    )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default CustomTable;
