import React, { useState } from "react";
import classes from "./Homepage.module.css";
import arrowicon from "../../images/arrowicon.svg";
import Codepopup from "./Codepopup";

function Homepage(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [role,setRole]=useState("");
  const handleOpen = (e) => {
    setRole(e.currentTarget.value);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  
  return (
    <>
      <div className={classes.container}>
        <h1 className={classes.title}>Let's play with NEMS</h1>
        <h5 className={classes.subtitle}>
          Make the best of your <span>timeline</span>. Schedule your events{" "}
          <span>effortlessly</span>
        </h5>

        <div className={classes.circlecontainer}>
          <div className={`${classes.circle}`}>Enjoyment</div>
          <div className={`${classes.circle}`}>Play</div>
          <div className={`${classes.circle}`}>
            Pleasure
          </div>
        </div>
      </div>
      <div className={classes.imgcontainer}>
        <div className={classes.employee}>
          <p className={classes.text}>Employee</p>
          <button
            className={`${classes.btn} ${classes.btnemp}`}
            onClick={handleOpen}
            value="employee"
          >
            <img src={arrowicon} alt="icon" />
          </button>
        </div>
        <div className={classes.event}>
          <p className={classes.text}>Event Manager</p>
          <button
            className={`${classes.btn} ${classes.btnevent}`}
            onClick={handleOpen}
            value="manager"
          >
            <img src={arrowicon} alt="icon" />
          </button>
        </div>
        <div className={classes.admin}>
          <p className={classes.text}>Admin</p>
          <button
            className={`${classes.btn} ${classes.btnadmin}`}
            onClick={handleOpen}
            value="admin"
          >
            <img src={arrowicon} alt="icon" />
          </button>
        </div>
      </div>
      <Codepopup open={dialogOpen} handleClose={handleClose} role={role}/>
    </>
  );
}

export default Homepage;
