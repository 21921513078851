import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import classes from "./CustomEvent.module.css";
import goldImg from "../../images/goldImg.png";
import publicicon from "../../images/public.svg";
import MyFormControl from "./MyFormControl";
import CustomRadioSelect from "./CustomRadioSelect";
import personicon from "../../images/personicon.svg";
import globalicon from "../../images/globalicon.svg";
import freeicon from "../../images/free-icon.svg";
import { createEvent, updateEvent } from "../../utils/ApiFunctions";
import CustomMultiSelect from "./CustomMultiSelect";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";

const timeOptions = [
  "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00",
  "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15",
  "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30",
  "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45",
  "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00",
  "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15",
  "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30",
  "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
  "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00",
  "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15",
  "22:30", "22:45", "23:00", "23:15", "23:30", "23:45", "24:00",
];


const eventTypeData = [
  "Private", "Corporate", "Basketball Team", "Schools", "Events", "Contribution Event", "Court"
];
const eventStatusData = ["Contract", "Signed", "Paid", "Cancelled", "Unpaid", "Future event", "Open to registration"];
const paymentStatusData = ["Paid", "Unpaid"];
const foodData = [
  "Drinks", "Snacks", "Popcorn", "Beers", "Combo(no-alcohol)", "Total combo", "Pizza", "Burgers", "BBQ", "Salads", "Tapas", "Full catering", "Other",
];
const genderData = ["Male", "Female", "Mix"];
const ageGroupData = ["10-15", "16-18", "18-30", "30-45", "45+", "Mix"];
const leadData = ["Facebook", "Instagram", "Word to mouth", "Google", "Other"];
const courtData = ["Gold 1", "Gold 2", "007", "Double", "747", "Add1", "Add2"];

function CustomEvent({ title, initialData }) {
  const [eventformat, setEventformat] = useState(
    initialData?.eventFormat || "Public"
  );
  const [payment, setPayment] = useState(initialData?.payment || "Transfer");
  const [hours, setHours] = useState(initialData?.hours || "1-2");
  const [eventType, setEventType] = useState(initialData?.type || "");
  const [eventStatus, setEventStatus] = useState(initialData?.status || "");
  const [paymentStatus, setPaymentStatus] = useState(
    initialData?.paymentStatus || ""
  );
  const navigate = useNavigate();
  const [food, setFood] = useState(initialData?.food || []);
  const [gender, setGender] = useState(initialData?.gender || "");
  const [ageGroup, setAgeGroup] = useState(initialData?.ageGroup || []);
  const [lead, setLead] = useState(initialData?.lead || "");
  const [court, setCourt] = useState();
  const [totalWorkers, setTotalWorkers] = useState(initialData?.totalWorkers || 0);
  const [totalWorkerHours, setTotalWorkerHours] = useState(initialData?.totalWorkerHours || 0);
  const [employeeSalary, setEmployeeSalary] = useState(initialData?.employeeSalary || 0);
  const [foodCost, setFoodCost] = useState(initialData?.foodCost || "");
  const [workers, setWorkers] = useState(initialData?.court || []);
  const handleWorkerChange = (e, name) => {
    setWorkers((prev) => {
      const workerExists = prev.find((worker) => worker.name === name);
      if (workerExists) {
        return prev.map((worker) =>
          worker.name === name
            ? { ...worker, noOfWorkers: e.target.value }
            : worker
        );
      } else {
        return [...prev, { name, noOfWorkers: e.target.value }];
      }
    });
  };

  const [courtName, setCourtName] = useState(
    initialData?.court?.map((c) => c.name) || []
  );
  const [participants, setParticipants] = useState(
    initialData?.noOfParticipants || ""
  );
  const [amount, setAmount] = useState(initialData?.amount || "");
  const [date, setDate] = useState(
    (initialData?.date && moment(initialData?.date).format("YYYY-MM-DD")) || ""
  );
  const [fromTime, setFromTime] = useState(
    (initialData?.fromTime && moment(initialData?.fromTime).format("HH:mm")) ||
    ""
  );
  const [toTime, setToTime] = useState(
    (initialData?.toTime && moment(initialData?.toTime).format("HH:mm")) || ""
  );



  const [form, setForm] = useState({
    eventName: initialData?.eventName || "",
    eventFormat: eventformat,
    type: initialData?.type || eventType,
    status: eventStatus,
    payment: payment,
    hours: hours,
    date: date,
    fromTime: initialData?.fromTime || "",
    toTime: initialData?.toTime || "",
    noOfParticipants: participants,
    court: court,
    totalWorkers: totalWorkers,
    totalWorkerHours: totalWorkerHours,
    employeeSalary: employeeSalary,
    amount: amount,
    gender: gender,
    ageGroup: ageGroup,
    paymentStatus: paymentStatus,
    food: food,
    foodCost: foodCost,
    description: initialData?.description || "",
    contactPerson: initialData?.contactPerson || "",
    contactNumber: initialData?.contactNumber || "",
    contactEmail: initialData?.contactEmail || "",
    needPhotographer: initialData?.needPhotographer || false,
    sendSurvey: initialData?.sendSurvey || false,
    surveyQuestion: initialData?.surveyQuestion || "",
    adminRemark: initialData?.adminRemark || "",
    lead: lead,
  });

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      eventFormat: eventformat,
      type: eventType,
      status: eventStatus,
      payment: payment,
      hours: hours,
      court: court,
      gender: gender,
      ageGroup: ageGroup,
      paymentStatus: paymentStatus,
      food: food,
      foodCost: foodCost,
      lead: lead,
      noOfParticipants: participants,
      amount: amount,
      date: date,
    }));
  }, [
    eventformat,
    lead,
    food,
    foodCost,
    paymentStatus,
    payment,
    gender,
    ageGroup,
    court,
    eventStatus,
    eventType,
    hours,
    participants,
    amount,
    date,
  ]);
  // useEffect(() => {
  //   const totalWorkers = workers.reduce((sum, worker) => sum + (worker.noOfWorkers || 0), 0);
  //   setForm((prev) => ({
  //     ...prev,
  //     totalWorkers,
  //   }));
  // }, [workers]);


  // useEffect(() => {
  //   // Calculate the sum of all noOfWorkers values
  //   const totalWorkersSum = workers.reduce((total, worker) => {
  //     return total + (parseInt(worker.noOfWorkers, 10) || 0);
  //   }, 0);
  //   // Calculate the time difference between fromTime and toTime in hours
  //   const timeGapInHours = (() => {
  //     if (!fromTime || !toTime) return 0;

  //     const startTime = moment(fromTime, "HH:mm");
  //     const endTime = moment(toTime, "HH:mm");

  //     // Calculate the difference in hours
  //     console.log(startTime, endTime);
  //     console.log(moment.duration(endTime.diff(startTime)).asHours());

  //     return moment.duration(endTime.diff(startTime)).asHours();
  //   })();

  //   // Calculate the total worker hours
  //   const totalWorkerHoursCalc = totalWorkersSum * timeGapInHours;
  //   // Update the form state with the calculated totalWorkers value
  //   // Calculate the employee salary based on the rate (e.g., 50)
  //   const salaryRate = 50; // You can adjust this value if needed
  //   const employeeSalaryCalc = totalWorkersSum * totalWorkerHoursCalc * salaryRate;
  //   setForm((prev) => ({
  //     ...prev,
  //     totalWorkers: totalWorkersSum,
  //     totalWorkerHours: totalWorkerHoursCalc,
  //     employeeSalary: employeeSalaryCalc
  //   }));

  //   setTotalWorkers(totalWorkersSum);
  //   setTotalWorkerHours(totalWorkerHoursCalc);
  //   setEmployeeSalary(employeeSalaryCalc);
  // }, [workers, fromTime, toTime]);

  useEffect(() => {
    // Get totalWorkers and totalWorkerHours from the form state
    const { totalWorkers, totalWorkerHours } = form;

    // Calculate the employee salary based on the provided formula
    const salaryRate = 50; // Adjust this value as needed
    const employeeSalaryCalc = totalWorkers * totalWorkerHours * salaryRate;

    // Update the form state with the calculated employee salary
    setForm((prev) => ({
      ...prev,
      employeeSalary: employeeSalaryCalc
    }));
  }, [form.totalWorkers, form.totalWorkerHours]);


  useEffect(() => {
    setCourt((prev) => workers);
  }, [workers, courtName]);

  useEffect(() => {
    const updatedCourt = courtName.map((name) => {
      const existingWorker = workers.find((worker) => worker.name === name);
      return existingWorker ? existingWorker : { name, noOfWorkers: "" };
    });
    setCourt(updatedCourt);
  }, [courtName]);
  useEffect(() => {
    if (date && fromTime) {
      const combinedFromTime = moment(`${date}T${fromTime}`);
      setForm((prev) => ({
        ...prev,
        fromTime: combinedFromTime.toDate(),
      }));
    }
  }, [date, fromTime]);

  useEffect(() => {
    if (date && toTime) {
      const combinedToTime = moment(`${date}T${toTime}`);
      setForm((prev) => ({
        ...prev,
        toTime: combinedToTime.toDate(),
      }));
    }
  }, [date, toTime]);
  const handleWheel = (e) => {
    e.target.blur(); // Remove focus to prevent value change
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleFromTimeChange = (e) => {
    setFromTime(e.target.value);
  };
  const handleToTimeChange = (e) => {
    setToTime(e.target.value);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFoodCostChange = (e) => {
    setFoodCost(Number(e.target.value)); // Convert the string to a number
  };


  const handleSubmit = async () => {
    let resp;
    if (title === "Create Event") {
      resp = await createEvent(form);
      if (resp) {
        navigate(`/admin/eventheader/all`);
      }
    } else {
      resp = await updateEvent(initialData?._id, form);
      if (resp) {
        navigate(-1);
      }
    }
  };
  return (
    <>
      <div className={classes.maincontainer}>
        <div className={classes.container}>
          <h3 className={classes.title}>{title}</h3>
          <form>
            <formgroup
              controlId="exampleForm.ControlInput1"
              className={classes.namecontainer}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Event Name
              </label>
              <input
                className={`${classes.eventname} ${classes.control}`}
                type="text"
                placeholder="Enter event name"
                name="eventName"
                id="custominput"
                onChange={handleChange}
                value={form.eventName}
                autoComplete="off"
              />
            </formgroup>
          </form>
          <FormControl className={`${classes.eventformat} py-4`}>
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Event Format
            </label>

            <div className={classes.radioselectcontainer}>
              <CustomRadioSelect
                img={publicicon}
                values={"Public"}
                setValue={setEventformat}
                styleclass={eventformat === "Public" ? "active" : ""}
              />
              <CustomRadioSelect
                img={globalicon}
                values={"Global"}
                setValue={setEventformat}
                styleclass={eventformat === "Global" ? "active" : ""}
                setForm={setForm}
                name={"eventFormat"}
              />
              <CustomRadioSelect
                img={personicon}
                values={"Local"}
                setValue={setEventformat}
                styleclass={eventformat === "Local" ? "active" : ""}
                setForm={setForm}
                name={"eventFormat"}
              />
            </div>
          </FormControl>
          <div className={classes.typestatus}>
            <div className={classes.formcontainer}>
              <MyFormControl
                title={"Event Type"}
                data={eventTypeData}
                value={eventType}
                setValue={setEventType}
              />
            </div>
            <div className={classes.formcontainer}>
              <MyFormControl
                title={"Event Status"}
                value={eventStatus}
                setValue={setEventStatus}
                data={eventStatusData}
              />
            </div>
          </div>
          <div className={classes.datetime}>
            <formgroup controlId="Date" className={classes.datecontainer}>
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Date
              </label>
              <input
                required
                type="date"
                name="date"
                placeholder="Select date"
                className={classes.control}
                onChange={handleDateChange}
                value={date}
                autoComplete="off"
              />
            </formgroup>
            <formgroup
              className={classes.time}
              controlId="exampleForm.ControlInput1"
            >
              {/* <div>
                <label
                  className={`${classes.timetext} ${classes.labell} ${classes.changedLabel}`}
                >
                  Time
                </label>
              </div> */}
              <div className={classes.timelmntcontainer}>
                {/* <input
                  type="time"
                  className={`${classes.timelmnt} ${classes.control}`}
                  placeholder="From"
                  name="fromTime"
                  onChange={handleFromTimeChange}
                  value={fromTime}
                  autoComplete="off"
                /> */}
                <MyFormControl
                  title={"From Time"}
                  value={fromTime}
                  setValue={setFromTime}
                  data={timeOptions}
                />
                {/* <input
                  type="time"
                  className={`${classes.timelmnt} ${classes.control} ${classes.datetime}`}
                  placeholder="To"
                  name="toTime"
                  onChange={handleToTimeChange}
                  value={toTime}
                  autoComplete="off"
                /> */}
                <MyFormControl
                  title={"To Time"}
                  value={toTime}
                  setValue={setToTime}
                  data={timeOptions}
                />
              </div>
            </formgroup>
          </div>
          <div className={classes.typestatus}>
            <div className={classes.formcontainer}>
              <MyFormControl
                title={"Gender"}
                data={genderData}
                value={gender}
                setValue={setGender}
              />
            </div>
            <div className={classes.formcontainer}>
              {/* <MyFormControl
                title={"Age Group"}
                data={ageGroupData}
                value={ageGroup}
                setValue={setAgeGroup}
              /> */}
              <CustomMultiSelect
                title={"Age Group"}
                data={ageGroupData}
                value={ageGroup}
                setValue={setAgeGroup}
              />
            </div>
          </div>

          <div className={classes.participantscourtcontainer}>
            <formgroup
              controlId="participants"
              className={classes.participantcontainer}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                No.of Participants
              </label>
              <input
                required
                type="number"
                name="noOfParticipants"
                placeholder="Enter no. of participants"
                className={classes.control}
                onChange={(e) => {
                  setParticipants(e.target.value);
                }}
                value={form.noOfParticipants}
                autoComplete="off"
                onWheel={handleWheel}
              />
            </formgroup>
            <div className={classes.formcontainer}>
              <CustomMultiSelect
                title={"Court (Multiple)"}
                data={courtData}
                value={courtName}
                setValue={setCourtName}
              />
            </div>
          </div>
          {courtName[0] && (
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              No. of workers
            </label>
          )}
          {courtName[0] && (
            <>
              <div className={classes.workerscontainer}>
                {courtName.map((name) => (
                  <inputgroup key={name} className={classes.workers}>
                    <input
                      placeholder={`Enter no. of workers`}
                      aria-label={`Enter no. of workers`}
                      aria-describedby={`basic-addon-${name}`}
                      className={classes.control}
                      value={
                        workers.find((c) => c.name === name)?.noOfWorkers || ""
                      }
                      name={name}
                      onChange={(e) => handleWorkerChange(e, name)}
                    />
                    <p id={`basic-addon-${name}`} className={classes.workerstext}>
                      <img src={goldImg} alt="Gold" className={classes.goldimg} />
                      {name}
                    </p>
                  </inputgroup>
                ))}
              </div>

              <div className={classes.totalworkercontainer}>
                <formgroup
                  controlId="participantcontainer"
                  className={classes.totalWorkers}
                >
                  <label className={`${classes.labell} ${classes.changedLabel}`}>
                    Total Workers
                  </label>
                  <input
                    required
                    type="number"
                    name="totalWorkers"
                    placeholder="Enter num. of workers"
                    className={classes.control}
                    onChange={handleChange}
                    value={form.totalWorkers}
                    autoComplete="off"
                    onWheel={handleWheel}
                  />
                </formgroup>
                <formgroup
                  controlId="participantcontainer"
                  className={classes.totalWorkers}
                >
                  <label className={`${classes.labell} ${classes.changedLabel}`}>
                    Total Hours
                  </label>
                  <input
                    required
                    type="number"
                    name="totalWorkerHours"
                    placeholder="Enter total hours"
                    className={classes.control}
                    onChange={handleChange}
                    value={form.totalWorkerHours}
                    autoComplete="off"
                    onWheel={handleWheel}
                  />
                </formgroup>

              </div>
              <div className="empsalorycontainer">
                <formgroup
                  controlId="participantcontainer"
                  className={classes.totalWorkers}
                >
                  <label className={`${classes.labell} ${classes.changedLabel}`}>
                    Employee Salory
                  </label>
                  <input
                    required
                    type="number"
                    name="employeeSalary"
                    placeholder="Enter emp. salary"
                    className={classes.control}
                    onChange={handleChange}
                    value={form.employeeSalary}
                    autoComplete="off"
                    disabled
                  />
                </formgroup>
              </div>
            </>
          )}
          <FormControl
            className={`${classes.eventformat} ${classes.paymentstatuscontainer}`}
          >
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Payment
            </label>
            <div className={classes.radioselectcontainer}>
              <CustomRadioSelect
                img={publicicon}
                values={"Transfer"}
                setValue={setPayment}
                styleclass={payment === "Transfer" ? "active" : ""}
              />
              <CustomRadioSelect
                img={globalicon}
                values={"In-person"}
                setValue={setPayment}
                styleclass={payment === "In-person" ? "active" : ""}
              />
              <CustomRadioSelect
                img={freeicon}
                values={"Free"}
                setValue={setPayment}
                styleclass={payment === "Free" ? "active" : ""}
              />
            </div>
          </FormControl>
          <div className={classes.leadsmaincontainer}>
            <MyFormControl
              title={"Payment Status"}
              value={paymentStatus}
              setValue={setPaymentStatus}
              data={paymentStatusData}
            />
          </div>
          <div className={classes.amountcontainer}>
            <formgroup
              controlId="participantcontainer"
              className={classes.amount}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Amount
              </label>
              <input
                required
                type="number"
                name="amount"
                placeholder="Enter amount"
                className={classes.control}
                onChange={(e) => setAmount(e.target.value)}
                value={form.amount}
                autoComplete="off"
                onWheel={handleWheel}
              />
            </formgroup>
            <formgroup
              controlId="participantcontainer"
              className={classes.amount}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Average amount per participant
              </label>
              <input
                required
                placeholder="Enter"
                disabled
                type="number"
                value={Math.ceil(form.amount / form.noOfParticipants)}
                name="avgAmount"
                className={classes.control}
                onChange={handleChange}
                autoComplete="off"
              />
            </formgroup>
          </div>

          <div className={classes.leadsmaincontainer}>
            <div style={{ width: '50%' }}>
              <CustomMultiSelect
                title={"Food (Multiple)"}
                data={foodData}
                value={food}
                setValue={setFood}
              />
            </div>

            <formgroup>
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Food cost
              </label>
              <input
                required
                type="number"
                name="foodCost"
                placeholder="Enter cost of food"
                className={classes.control}
                onChange={(e) => setFoodCost(e.target.value)}
                value={form.foodCost}
                onWheel={handleWheel}
                autoComplete="off"
              />
            </formgroup>
          </div>
          <form>
            <formgroup
              controlId="exampleForm.ControlInput1"
              className={classes.namecontainer}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Description
              </label>
              <input
                className={`${classes.eventname} ${classes.control}`}
                type="text"
                name="description"
                placeholder="Enter description"
                onChange={handleChange}
                value={form.description}
                autoComplete="off"
              />
            </formgroup>
          </form>
          <FormControl className={`${classes.eventformat} py-4`}>
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Actual hours in the place
            </label>
            <div className={classes.radioselectcontainer}>
              <CustomRadioSelect
                values={"1-2"}
                setValue={setHours}
                otherclass={"hours"}
                styleclass={hours === "1-2" ? "active" : ""}
              />
              <CustomRadioSelect
                values={"2-3"}
                setValue={setHours}
                otherclass={"hours"}
                styleclass={hours === "2-3" ? "active" : ""}
              />
              <CustomRadioSelect
                values={"3-4"}
                setValue={setHours}
                otherclass={"hours"}
                styleclass={hours === "3-4" ? "active" : ""}
              />
              <CustomRadioSelect
                values={"4+"}
                setValue={setHours}
                otherclass={"hours"}
                styleclass={hours === "4+" ? "active" : ""}
              />
            </div>
          </FormControl>
          <div className={classes.checkboxcontainer}>
            <input // prettier-ignore
              type="checkbox"
              id="photographer"
              className={classes.checkbox}
              name="needPhotographer"
              onChange={handleChange}
              checked={form.needPhotographer}
              autoComplete="off"
            />
            <label htmlFor="photographer" className={classes.checkboxtext}>
              Need a photographer
            </label>
          </div>
          <div className={classes.amountcontainer}>
            <formgroup
              controlId="participantcontainer"
              className={classes.amount}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Contact Person
              </label>
              <input
                required
                type="text"
                name="contactPerson"
                placeholder="Enter person name"
                className={classes.control}
                onChange={handleChange}
                value={form.contactPerson}
                autoComplete="off"
              />
            </formgroup>
            <formgroup
              controlId="participantcontainer"
              className={classes.amount}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Contact Number
              </label>
              <input
                required
                type="number"
                name="contactNumber"
                placeholder="Enter contact number"
                className={classes.control}
                onChange={handleChange}
                value={form.contactNumber}
                autoComplete="off"
                onWheel={handleWheel}
              />
            </formgroup>
          </div>
          <div className={classes.emailcontainer}>
            <formgroup
              controlId="participantcontainer"
              className={classes.email}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Contact Email Id
              </label>
              <input
                required
                type="email"
                name="contactEmail"
                placeholder="Enter email id"
                className={classes.control}
                onChange={handleChange}
                value={form.contactEmail}
                autoComplete="off"
              />
            </formgroup>
          </div>
          {/* <div className={classes.techmanagercontainer}>
          <MyFormControl title={"Tech Manager"} />
          </div> */}
          <div className={classes.howleadcontainer}>
            <MyFormControl
              title={"How did you find our lead?"}
              data={leadData}
              value={lead}
              setValue={setLead}
            />
          </div>
          <div className={classes.checkboxcontainer}>
            <input // prettier-ignore
              type="checkbox"
              id="survey"
              className={classes.checkbox}
              name="sendSurvey"
              onChange={handleChange}
              checked={form.sendSurvey}
              autoComplete="off"
            />
            <label htmlFor="survey" className={classes.checkboxtext}>
              Send survey at the end of an event
            </label>
          </div>
          <div className={classes.surveycontainer}>
            <formgroup
              controlId="participantcontainer"
              className={classes.survey}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Survey Question
              </label>
              <input
                required
                type="text"
                name="surveyQuestion"
                placeholder="Enter survey question"
                className={classes.control}
                onChange={handleChange}
                value={form.surveyQuestion}
                autoComplete="off"
              />
            </formgroup>
          </div>
          <div className={classes.remarkcontainer}>
            <formgroup
              controlId="participantcontainer"
              className={classes.survey}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Remark
              </label>
              <textarea
                required
                type="text"
                name="adminRemark"
                placeholder="Enter Remark"
                className={classes.control}
                onChange={handleChange}
                value={form.adminRemark}
              />
            </formgroup>
          </div>
          <div className={classes.btncontainer}>
            <button className={classes.btnsubmit} onClick={handleSubmit}>
              Submit
            </button>
            <button className={classes.btncancel} onClick={() => navigate(-1)}>
              Cancel
            </button>
          </div>
        </div>
        <div className={classes.ballimg}>
          <div className={classes.bimg}></div>
        </div>
      </div>
    </>
  );
}

export default CustomEvent;
