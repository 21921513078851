import React from 'react';
import CustomEvent from '../../reusable/CustomEvent';

function CreateEvent(props) {
    return (
        <>
        <CustomEvent title={"Create Event"}/>
        </>
    );
}

export default CreateEvent;