import React, { useEffect, useState } from 'react';
import classes from './RegisteredEvent.module.css';
import CustomTable from '../reusable/CustomTable';
import moment from 'moment';
import { getregisteredEvents } from '../../utils/ApiFunctions';
import Cookies from "js-cookie";
import Loader from '../reusable/Loader';
import NoData from '../reusable/NoData';
import MyPagination from '../reusable/MyPagination';


  const columns = [
    {
      Header: "#",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Time",
      accessor: "time",
    },
    {
      Header: "Format",
      accessor: "format",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header:"Transportation",
      accessor:"transportationStatus"
    },
    {
      Header:"",
      accessor:"action"
    }
  ];
  

function RegisteredEvent(props) {
  const [events,setEvents]=useState([{}]);
  const [page,setPage]=useState(1);
  const [totalPages,setTotalPages]=useState("");
  const [update,setUpdate]=useState(true);
  const employeeId = Cookies.get("userid");
  const [loading, setLoading] = useState(true);
  const getOpenEvents=async()=>{
    const resp=await getregisteredEvents(page,employeeId);
    if(resp){
      setTotalPages(resp.totalPages);
      const temp=resp.data?.events?.map((event,index)=>(
        {
          
          _id:event._id,
          id:(page - 1) *5 + index + 1,
          name:event.eventName,
          date:moment(event.date).format('YYYY-MM-DD'),
          time:`${moment(event.fromTime).format('HH:mm')}-${moment(event.toTime).format('HH:mm')}`,
          format:event.eventFormat,
          type:event.type,
          action:"cancelevent"
        }
      ))
      const temp2=resp.data?.registeredEmployees?.map(e=>(
        {
          eventid:e.event._id,
          transportationStatus:e.transportationStatus
        }
      ))
      const updatedTemp = temp?.map(event => {
        const matchingRegisteredEmployee = temp2.find(re => re.eventid === event._id);
        return matchingRegisteredEmployee
        ? { ...event, transportationStatus: matchingRegisteredEmployee.transportationStatus === undefined ? '-' : (matchingRegisteredEmployee.transportationStatus ? 'Approved' : 'Rejected') }
        : event;
      });
      setEvents(updatedTemp);
      
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(()=>{
    
    getOpenEvents();
  },[update,page]);
    return (
      <>
      {loading && <Loader />}
      {!loading && !events[0] &&
        <NoData title={"No Events Approved"} subtitle={"Wait for the Admin to approve the events"}/>
      }
      {!loading && events[0] &&
        <div className={classes.container}>
            <CustomTable responsive={'tableTabletEmployeeEvents'} columns={columns} data={events} update={update} setUpdate={setUpdate} showRegisterCancelOption={false}/>
            <MyPagination page={page} setPage={setPage} totalPages={totalPages}/>
        </div>}
        </>
    );
}

export default RegisteredEvent;