import React from 'react';
import moment from 'moment';
import classes from './MyCalendar.module.css';

const CustomHeader = ({ label }) => {
  const today = moment().format('ddd');
  const isToday = label === today;
  return (
    <div className={isToday ? classes.currentDayText :''}>
      {label}
    </div>
  );
};

export default CustomHeader;
