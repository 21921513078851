import React from "react";
import neojaLogo from "../../images/neojaLogo.svg";
import classes from "./Header.module.css";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const navigate=useNavigate();
  return (
    <>
      <div className={classes.logo}>
        <img src={neojaLogo} alt="neoja logo" onClick={()=>{navigate('/')}} className={classes.neojalogo}/>
        <p className={classes.logotext}>Neoja Event Management System</p>
      </div>
    </>
  );
}

export default Header;
