import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import classes from "./DialogCard.module.css";
import xmarkicon from "../../images/xmarkicon.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#DB545D", // Matches the red color in the image
    },
    secondary: {
      main: "#00000080", // Custom color for secondary actions if needed
    },
  },
});

function DialogCard({
  open,
  handleClose,
  title,
  content,
  btnOne,
  btnTwo,
  btnOneAction,
  btnOneDisabled,
}) {
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };
  const contentRef = useRef(null);

    useEffect(() => {
      if (open) {
        const timer = setTimeout(() => {
            if (contentRef.current) {
              const input = contentRef.current.querySelector('input');
              
                input.focus();
            }
        }, 100); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }
    }, [open]);

    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          btnOneAction();
        }
      };
  
      if (open) {
        document.addEventListener("keydown", handleKeyPress);
      }
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [open, btnOneAction]);
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
            margin: "0 auto",
          },
        }}
        
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogtitle}
          style={{ color: "#000" }}
        >
          {title}
          <div className={classes.closebtn} onClick={handleClose}>
            <img src={xmarkicon} alt="" />
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div ref={contentRef}>
            {content}
            </div>
        </DialogContent>
        <DialogActions className={classes.btncontainer}>
          {btnOne && (
            <Button
              type="button"
              onClick={btnOneAction}
              className={classes.btnyes}
              autoFocus
              disabled={btnOneDisabled}
            >
              {btnOne}
            </Button>
          )}
          {btnTwo && (
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.btnno}
            >
              {btnTwo}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default DialogCard;
