import React from 'react';
import classes from "./CustomCheckbox.module.css";

function CustomCheckbox({text,setCheck,checked}) {
    return (
        <>
        <div className={classes.checkboxcontainer}>
            <input // prettier-ignore
              type="checkbox"
              id="photographer"
              className={classes.checkbox}
              onChange={(e)=>setCheck(e.target.checked)}
              checked={checked}
            />
            <label htmlFor="photographer" className={classes.checkboxtext}>
              {text}
            </label>
          </div>
        </>
    );
}

export default CustomCheckbox;