import { Pagination, Stack, styled } from '@mui/material';
import classes from './MyPagination.module.css';
import React from 'react';


const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      fontSize: "1.6rem",
      fontWeight: "600",
      "&.Mui-selected": {
        background: "linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%)",
        color: "white",
        fontSize: "1.2rem",
      },
      "&:hover": {
        background: "linear-gradient(148.15deg, #F87B83 1.3%, #FFFFFF 201.37%)",
        // Replace with your custom hover color
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      "&:hover": {
        background: "transparent",
      },
    },
  }));
function MyPagination({page,setPage,totalPages}) {
    const handlePageChange = (event, value) => {
        setPage(value);
      };
    return (
        <div className={classes.pagination}>
        <Stack spacing={2}>
          <CustomPagination count={totalPages} page={page} onChange={handlePageChange}/>
        </Stack>
      </div>
    );
}

export default MyPagination;