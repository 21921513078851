import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import classes from "./CustomEventList.module.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CustomTable from "./CustomTable";
import EventOptions from "../admin/events comps/EventOptions";
import MyPagination from "./MyPagination";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Contact Person",
    accessor: "assignedTo",
  },
  {
    Header: "Participants",
    accessor: "employees",
  },
  {
    Header: "Format",
    accessor: "format",
  },
  {
    Header: "Court",
    accessor: "court",
  },
  {
    Header: "Event Status",
    accessor: "status",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus",
  },

  {
    Header: "",
    accessor: "",
  },
];

function CustomEventList({ events, page, setPage, totalPages, setDeleted, responsive }) {
  return (
    <>
      <div className={classes.tablecontainer}>
        <CustomTable
          responsive={responsive}
          columns={columns}
          data={events}
          OptionsComponent={EventOptions}
        />
      </div>
      {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
    </>
  );
}

export default CustomEventList;
