import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classes from "./Header.module.css";

function Header({activeTab}) {
    // const [activeTab, setActiveTab] = useState("events");
  return (
    <>
      <div className={classes.tabheader}>
        <div className={classes.eventcal}>
          <NavLink
            to={'/admin/eventheader/all'}
            className={`${classes.style} ${activeTab==='admin'?classes.active:""}`}
            // activeClassName={classes.active}
            // onClick={() => setActiveTab("events")}
          >
            Events
          </NavLink>
          <NavLink
            to={'/calendar'}
            className={`${classes.style} ${activeTab==='calendar'?classes.active:""}`}
            // onClick={() => setActiveTab("calendar")}
            // activeClassName={classes.active}
          >
            Calendar
          </NavLink>
          <NavLink
            to={"/adminemp"}
            className={`${classes.style} ${activeTab==='employee'?classes.active:""}`}
            // onClick={() => setActiveTab("employees")}
            // activeClassName={classes.active}
          >
            Employees
          </NavLink>
        </div>
       
        
      </div>
      
    </>
  )
}

export default Header;