import React from 'react';
import leftarrowIcon from "../../../images/leftarrowIcon.svg";
import rightarrowIcon from "../../../images/rightarrowIcon.svg";
import classes from "./CustomToolbar.module.css";

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        const newDate = new Date(toolbar.date);
        newDate.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate('prev', newDate);
      };
    
      const goToNext = () => {
        const newDate = new Date(toolbar.date);
        newDate.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next', newDate);
      };
    
      const label = () => {
        const date = toolbar.date;
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${month} ${year}`;
      };
    
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack} className={`${classes.active}`}><img src={leftarrowIcon} alt="" /></button>
        <button type="button" onClick={goToNext}><img src={rightarrowIcon} alt="" /></button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
    </div>
  );
};

export default CustomToolbar;
