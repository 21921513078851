import axios from "axios";
import Cookies from "js-cookie";

export const api = axios.create({
  baseURL: "https://nems-backend-ca39b17ab689.herokuapp.com/api/v1",
  // baseURL: "http://localhost:8000/api/v1",
});

export const getHeader = () => {

  return {
    Authorization: `Bearer ${Cookies.get('jwt')}`,
    "Content-type": "application/json",
  };
};

// login user
export const loginUser = async (userDetails) => {
  try {
    const resp = await api.post("/users/login", userDetails);
    if (resp.status >= 200 && resp.status < 300) {
      return resp.data;
    }
  } catch (error) {
    return error;
  }
};

// create event
export const createEvent = async (eventForm) => {
  try {
    const resp = await api.post("/events/create", eventForm, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get all events
export const getAllEvents = async () => {
  try {
    const resp = await api.get(`/events/getall`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get event
export const getEvents = async () => {
  try {
    const resp = await api.get(`/events/get`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

// get upcoming event
export const getUpcomingEvents = async () => {
  try {
    const resp = await api.get(`/events/getupcoming`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get finished event
export const getFinishedEvents = async () => {
  try {
    const resp = await api.get(`/events/getfinished`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

export const getCancelledEvents = async () => {
  try {
    const resp = await api.get(`/events/getcancelled`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}


// get event by id
export const getOneEvent = async (id) => {
  try {
    const resp = await api.get(`/events/get/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// update event by id
export const updateEvent = async (id, form) => {
  try {
    const resp = await api.put(`/events/edit/${id}`, form, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// delete event by id
export const delEvent = async (id) => {
  try {
    const resp = await api.delete(`/events/delete/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.status;
  } catch (error) {
    return null;
  }
}

// update employee by id
export const updateEmployee = async (id, form) => {
  try {
    const resp = await api.put(`/employees/edit/${id}`, form, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// delete employee by id
export const delEmployee = async (id) => {
  try {
    const resp = await api.delete(`/employees/delete/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.status;
  } catch (error) {
    return null;
  }
}
// get employees
export const getEmployees = async () => {
  try {
    const resp = await api.get(`/employees/get`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

// create emp
export const createEmployee = async (form) => {
  try {
    const resp = await api.post("/employees/create", form, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get employee by id
export const getOneEmployee = async (id) => {
  try {
    const resp = await api.get(`/employees/getone/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// emp register
export const register = async (form) => {
  try {
    const resp = await api.post("/registeremployee/create", form, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

// cancelregistration
export const cancel = async (empid, eventid) => {
  try {
    const resp = await api.delete(`/registeremployee/cancel/${empid}/${eventid}`, {
      headers: { ...getHeader() }
    });
    return resp.status;
  } catch (error) {
    return null;
  }
}
// get unregisteredevents
export const getUnregisteredEvents = async (id) => {
  try {
    const resp = await api.get(`/registeremployee/getEvents/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get registeredevents
export const getregisteredEvents = async (page, id) => {
  try {
    const resp = await api.get(`/registeremployee/getRegisteredEvents/${id}?page=${page}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

// get registerations
export const getregisterations = async (id) => {
  try {
    const resp = await api.get(`/registeremployee/getregistrations/${id}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}

// update registration by id
export const updateRegistration = async (id, form) => {
  try {
    const resp = await api.put(`/registeremployee/updateregistration/${id}`, form, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
// get approved events
export const getAllApprovedEvents = async (page) => {
  try {
    const resp = await api.get(`/events/getapproved?page=${page}`, {
      headers: { ...getHeader() }
    });
    return resp.data;
  } catch (error) {
    return null;
  }
}
