import React, { useState } from "react";
import classes from "./AdminHeader.module.css";
import EventButtons from "./events comps/EventButtons";
// import CustomEventHeader from "../reusable/CustomEventHeader";
import { NavLink, Outlet } from "react-router-dom";

const AdminHeader=()=> {
  
  return (
    <>
      <div className={classes.tabheader}>
        <div className={classes.eventcal}>
          <NavLink
            to={'/admin/eventheader/upcoming'}
            className={`${classes.style} ${classes.active}`}
            activeClassName={classes.active}
          >
            Events
          </NavLink>
          <NavLink
            to={'/calendar'}
            className={`${classes.style}`}
            
          >
            Calendar
          </NavLink>
          <NavLink
            to={"/adminemp"}
            className={`${classes.style}`}
          >
            Employees
          </NavLink>
        </div>
       <EventButtons />
        
      </div>
      <Outlet/>
    </>
  );
}

export default AdminHeader;
