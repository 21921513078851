import React, { useState } from 'react';
import DialogCard from '../reusable/DialogCard';
import classes from "./RemarkPopup.module.css";
import { updateEvent } from '../../utils/ApiFunctions';

function RemarkPopup({open,handleClose,id,update,setUpdate}) {
    const [managerRemark,setManagerRemark]=useState("");
    const remarkHandler=async()=>{
        const resp=await updateEvent(id,{
            managerRemark:managerRemark
        });
        if(resp){
            setUpdate(!update);
            handleClose();
        }
    }
    return (
        <div className={classes.dialog}>
        <DialogCard open={open} handleClose={handleClose} title={"Remark"}content={<input
                className={`${classes.control}`}
                type="text"
                placeholder="Enter Remark"
                id="custominput"
                name='managerRemark'
                value={managerRemark}
                onChange={(e)=>setManagerRemark(e.target.value)}
              />} btnOne={"Save"} btnTwo={"Cancel"} btnOneAction={remarkHandler}/>
        </div>
    );
}

export default RemarkPopup;