import React, { useEffect, useState } from "react";
import DialogCard from "../../reusable/DialogCard";
import classes from "./EditEmployee.module.css";
import { getEmployees, getOneEmployee, updateEmployee } from "../../../utils/ApiFunctions";

function EditEmployee({ open, handleClose ,id}) {
  const [form,setForm]=useState();

  useEffect(()=>{
    const getEmp=async()=>{
      const resp=await getOneEmployee(id);
      setForm(resp.data.data);
    }
    getEmp();
  },[])

  const editHandler=async()=>{
    const resp=await updateEmployee(id,form);
    if(resp){
      window.location.reload();
    }
  }
  const handleChange = (e) => {
    const { name, value} = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    form&&(<div className={classes.dialog}>
      <DialogCard
        open={open}
        handleClose={handleClose}
        title={"Edit Employee"}
        content={
          <>
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Name
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter name"
              id="custominput"
              value={form?.name}
              name="name"
              onChange={handleChange}
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Phone no
            </label>
            <input
              className={`${classes.control}`}
              type="number"
              placeholder="Enter number"
              id="custominput"
              value={form?.phoneNo}
              name="phoneNo"
              onChange={handleChange}
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Address
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter address"
              id="custominput"
              value={form?.address}
              name="address"
              onChange={handleChange}
            />
            <label className={`${classes.labell} ${classes.changedLabel}`}>
              Email
            </label>
            <input
              className={`${classes.control}`}
              type="text"
              placeholder="Enter email"
              id="custominput"
              value={form?.email}
              name="email"
              onChange={handleChange}
            />
            <label className={`${classes.labell} `}>
              Age
            </label>
            <input
              className={`${classes.control} ${classes.lastcontrol}`}
              type="number"
              placeholder="Enter age"
              id="custominput"
              value={form?.age}
              name="age"
              onChange={handleChange}
            />
          </>
        }
        btnOne={"Save"}
        btnTwo={"Cancel"}
        btnOneAction={editHandler}
      />
    </div>)
  );
}

export default EditEmployee;
