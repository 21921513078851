import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classes from './CustomDialog.module.css';
import xmarkicon from "../../images/xmarkicon.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: '#DB545D',
    },
    secondary: {
      main: '#00000080', 
    },
  },
});

function CustomDialog({ open, handleClose ,maincontent,subcontent,btnOne,btnTwo,title,btnOneClick}) {
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
       onClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogtitle} style={{color:"#DB545D"}}>
          {title}
          <div className={classes.closebtn} onClick={handleClose}>
          <img src={xmarkicon} alt=""  />
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description" >
            <p className={classes.maincontent}>{maincontent}</p>
            <p className={classes.subcontent}>{subcontent}</p>
          </DialogContentText>
          
        </DialogContent>
        <DialogActions className={classes.btncontainer}>
          
          <Button onClick={btnOneClick} className={classes.btnyes} autoFocus>
            {btnOne}
          </Button>
          <Button onClick={handleClose} color="primary" className={classes.btnno}>
            {btnTwo}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomDialog;