import { FormControl, FormLabel, MenuItem, Select, styled } from '@mui/material';
import classes from "./MyFormControl.module.css";
import React from 'react';

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    width: "100%",
    margin: "0",
    '& .MuiOutlinedInput-root': {
      borderRadius: "1.2rem",
      height: "4rem",
      '&:hover fieldset': {
        border: '1px solid #DB545D',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #DB545D',
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: '#DB545D',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0,0,0,0.1)',
  },
  }));
  
  
  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "1.4rem",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontStyle: "normal",
    color: "#aaa",
  
    "&.Mui-selected": {
      color: "#DB545D",
      backgroundColor: "rgba(248, 123, 131, 0.2) !important",

      "b": {
        color: "#DB545D",
      },

      "&:hover": {
        backgroundColor: "rgba(248, 123, 131, 0.2) !important",
      },
    },
    "&.Mui-hover":{
      backgroundColor: "#DB545D",
    }
  }));
  const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: "1.4rem",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#00000080",
  }));
  
  const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
      fontSize: "1.4rem",
      fontFamily: "Poppins",
      fontWeight: "500",
      lineHeight:"2.4",
      color: "#000",
    }
}));

function MyFormControl({title,data,value,setValue,setForm,disabled}) {
  const changeHandler=(event)=>{
    setValue(event.target.value);
  }
    return (
        <CustomFormControl
              sx={{ m: 1, minWidth: 120 }}
              style={{ width: "100%", margin: "0" }}
            >
              <CustomFormLabel id="event-type-label" className={classes.labell}>
                {title}
              </CustomFormLabel>
              <CustomSelect
                value={value}                
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={changeHandler}
                disabled={disabled}
              >
                <CustomMenuItem value="">
                  <em className={classes.selecttext} key={"select"}>Select</em>
                </CustomMenuItem>
                {data?.map((item,index)=>(
                  <CustomMenuItem className={classes.selecttext} key={index} value={item}>
                    {item}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </CustomFormControl>
    );
}

export default MyFormControl;