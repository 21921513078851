import React, { useEffect, useState } from "react";
import CustomTable from "../reusable/CustomTable";
import classes from "./OpenEvents.module.css";
import {
  getUnregisteredEvents,
  getUpcomingEvents,
  register,
} from "../../utils/ApiFunctions";
import moment from "moment";
import Cookies from "js-cookie";
import Loader from "../reusable/Loader";
import NoData from "../reusable/NoData";
import MyPagination from "../reusable/MyPagination";
// const events = [
//     {
//       id: 1,
//       name: "Company anniversary",
//       date: "07/07/24",
//       time: "14:30 - 17:00",
//       format: "Local",
//       type: "Private",

//     },
//     {
//       id: 2,
//       name: "Sports Event",
//       date: "07/07/24",
//       time: "15:00 - 18:00",
//       format: "Global",
//       type: "Schools",

//     },
//     {
//       id: 3,
//       name: "Bring kids to office event",
//       date: "30/06/24",
//       time: "13:45 - 16:00",
//       format: "Tournament",
//       type: "Private",

//     },
//     {
//       id: 4,
//       name: "Family reunion event",
//       date: "07/07/24",
//       time: "14:00 - 16:00",
//       format: "Local",
//       type: "Corporate",

//     },
//   ];

const columns = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Format",
    accessor: "format",
  },
  {
    Header: "Type",
    accessor: "type",
  },

  {
    Header: "",
    accessor: "action",
  },
];
function OpenEvents(props) {
  const [events, setEvents] = useState([]);
  const [courts, setCourts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [update, setUpdate] = useState(false);
  const employeeId = Cookies.get("userid");
  const [loading, setLoading] = useState(true);
  const [unregisteredEventIds, setUnregisteredEventIds] = useState([]);

  useEffect(() => {
    const fetchUnreg = async () => {
      const registeredResp = await getUnregisteredEvents(employeeId);
      console.log(registeredResp);
      if (registeredResp) {
        const unregIds = registeredResp.data.events.map((event) => event._id);
        setUnregisteredEventIds(unregIds);
      }
    };
    fetchUnreg();
  }, [employeeId,update]);
  // const getOpenEvents = async () => {
  //   const resp = await getUpcomingEvents(page);
  //   console.log(resp);
  //   if (resp) {
  //     const temp = resp.data.events?.map((event,index) => {
  //       const isNotRegistered = unregisteredEventIds?.includes(event._id);
  //       return {
  //         _id: event._id,
  //         id: (page - 1) *5 + index + 1,
  //         name: event.eventName,
  //         date: moment(event.date).format("YYYY-MM-DD"),
  //         time: `${moment(event.fromTime).format("HH:mm")}-${moment(
  //           event.toTime
  //         ).format("HH:mm")}`,
  //         format: event.eventFormat,
  //         type: event.type,
  //         action: isNotRegistered ? "register" : "cancelevent",
  //       };
  //     });
  //     setEvents(temp);
  //     setTotalPages(resp.totalPages);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };

  const getOpenEvents = async () => {
    const resp = await getUpcomingEvents(page);
    console.log(resp);
    if (resp) {
      const temp = resp.data.events
        ?.filter(event => unregisteredEventIds.includes(event._id)) // Filter here
        .map((event, index) => {
          return {
            _id: event._id,
            id: (page - 1) * 5 + index + 1,
            name: event.eventName,
            court : event?.court,
            date: moment(event.date).format("YYYY-MM-DD"),
            time: `${moment(event.fromTime).format("HH:mm")}-${moment(event.toTime).format("HH:mm")}`,
            format: event.eventFormat,
            type: event.type,
            action: "register",
          };
        });
      setEvents(temp);
      setTotalPages(resp.totalPages);
      setLoading(false);
      console.log(events);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    getOpenEvents();
  }, [update, page, unregisteredEventIds]);
  return (
    <>
      {loading && <Loader />}
      {!loading && !events[0] && (
        <NoData
          title={"No Open Events"}
          subtitle={"Wait for the events to be created"}
        />
      )}
      {!loading && events[0] && (
        <div className={classes.container}>
          <CustomTable
            responsive={'tableTabletEmployeeEvents'}
            columns={columns}
            data={events}
            update={update}
            setUpdate={setUpdate}
          />
          {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
        </div>
      )}
    </>
  );
}

export default OpenEvents;
