import React from 'react';
import CustomDialog from '../reusable/CustomDialog';

function EndEventPopup({open,handleClose,handleEndEvent,endEventState,setEndEventState}) {
    return (
        <CustomDialog
        open={open}
        handleClose={handleClose}
        title={"End Event"}
        maincontent={"Are you sure you want to end this event?"}
        subcontent={"You are about to end this event. This won't stay in events any longer."}
        btnOne={"End Event"}
        btnTwo={"Cancel"}
        btnOneClick={handleEndEvent}
      />
        
    );
}

export default EndEventPopup;