import React, { useEffect, useState } from "react";
import classes from "./ManagerList.module.css";
import CustomTable from "../reusable/CustomTable";
import ViewOption from "./ViewOption";
import { getAllApprovedEvents,getEvents } from "../../utils/ApiFunctions";
import moment from "moment";
import NoData from "../reusable/NoData";
import Loader from "../reusable/Loader";
import MyPagination from "../reusable/MyPagination";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Event manager",
    accessor: "eventManager",
  },
  {
    Header: "Tech manager",
    accessor: "techManager",
  },
  {
    Header: "Participants",
    accessor: "participants",
  },
  {
    Header: "",
    accessor: "",
  },
];

function ManagerList(props) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [events, setEvents] = useState("");
  const [loading, setLoading] = useState(true);
  // const getAllEvents = async () => {
  //   const resp = await getEvents();
  //   console.log(resp);
  //   if (resp) {
  //     const temp = resp?.data?.events?.map((event) => {
  //       return {
  //         _id: event._id,
  //         id: event.eventid,
  //         name: event.eventName,
  //         date: moment(event.date).format("YYYY-MM-DD"),
  //         time: `${moment(event.fromTime).format("HH:mm")}-${moment(
  //           event.toTime
  //         ).format("HH:mm")}`,
  //         participants: event.noOfParticipants,
  //         eventManager: event.eventManager,
  //         techManager: event.techManager,
  //       };
  //     });
  //     console.log(temp);

  //     setEvents(temp);
  //     setTotalPages(resp.totalPages);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };
  const getAllEvents = async () => {
    const resp = await getEvents();
    console.log(resp);
    if (resp) {
      const now = moment(); // Current date and time
      const temp = resp?.data?.events
        ?.filter((event) => moment(event.date).isSameOrAfter(now, 'day') && event.status !== "Future event")
        .map((event) => {
          return {
            _id: event._id,
            id: event.eventid,
            name: event.eventName,
            date: moment(event.date).format("YYYY-MM-DD"),
            time: `${moment(event.fromTime).format("HH:mm")}-${moment(
              event.toTime
            ).format("HH:mm")}`,
            participants: event.noOfParticipants,
            eventManager: event.eventManager,
            techManager: event.techManager,
          };
        })
        .sort((a, b) => {
          const dateComparison = moment(a.date).diff(moment(b.date));
          if (dateComparison === 0) {
            return moment(a.time.split('-')[0], "HH:mm").diff(moment(b.time.split('-')[0], "HH:mm"));
          }
          return dateComparison;
        });

      console.log(temp);

      setEvents(temp);
      setTotalPages(resp.totalPages);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllEvents();
  }, []);
  return (
    <>
      {loading && <Loader />}
      {!loading && !events[0] && (
        <NoData
          title={"No Events assigned"}
          subtitle={"Wait for the events to be assigned"}
        />
      )}
      {!loading && events[0] && (
        <div className={classes.container}>
          <CustomTable
            responsive={"tableTabletManager"}
            columns={columns}
            data={events}
            OptionsComponent={ViewOption}
          />
          {/* <MyPagination page={page} setPage={setPage} totalPages={totalPages} /> */}
        </div>
      )}
    </>
  );
}

export default ManagerList;
