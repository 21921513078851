import React, { useState } from "react";

import classes from './CustomRegisterCancelOption.module.css';

function CustomRegisterCancelOption({img,PopupComponent,text,id,update,setUpdate,courts}) {
    const [registerDialogOpen, setRegisterDialogOpen] = useState(false);

    const handleRegisterOpen = () => {
      setRegisterDialogOpen(true);
    };
  
    const handleRegisterClose = () => {
      setRegisterDialogOpen(false);
    };
  return (
    <>
      <p onClick={handleRegisterOpen} className={classes.text}>
        <img src={img} alt="" className={classes.registerimg}/>
        {text}
      </p>
      <PopupComponent
        open={registerDialogOpen}
        handleClose={handleRegisterClose}
        id={id}
       update={update}
       setUpdate={setUpdate}
       courts={courts}
      />
    </>
  );
}

export default CustomRegisterCancelOption;
