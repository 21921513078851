import React, { useEffect, useRef, useState } from "react";
import DialogCard from "../reusable/DialogCard";
import classes from "./Codepopup.module.css";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../utils/ApiFunctions";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { userDataActions } from "../store";

function Codepopup({ open, handleClose, role }) {
  const [data, setData] = useState("");
  
  const inputChangeHandler = (e) => {
    setData({ [e.target.name]: e.target.value, role: role });
  };
  const navigate = useNavigate();
  const btnOneAction = async (e) => {
    const resp = await loginUser(data);
    console.log(resp);
    Cookies.set("jwt", resp.token);
    if (resp.data) {
      console.log(resp.data.user);
      Cookies.set("userid", resp.data.user._id);
      if (resp.data.user.role === "admin") {
        // localStorage.setItem('user',resp.data.user._id);
        navigate(`/admin/eventheader/upcoming`);
      }
       else {
        navigate(`/${resp.data.user.role}`,{ state: { userName: resp.data.user.name } });
        const userRole = resp?.data?.user?.role;
        if(userRole == 'employee'){
          Cookies.set("empName", resp?.data?.user?.name);
        }
      }


    } else {
      // alert("You are not authorized!");
    }
  };
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   const handleKeyPress = (e) => {
  //     if (e.key === "Enter") {
  //       btnOneAction();
  //     }
  //   };

    

  //   if (open) {
  //     document.addEventListener("keydown", handleKeyPress);
  //     const input = inputRef.current;
  //     if (input) {
  //       input.focus();
  //     }
  //   } else {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   }

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [open]);

  return (
    // <div className={classes.dialog}>
    <DialogCard
      open={open}
      handleClose={handleClose}
      title={"Enter Code"}
      content={
        <input
          className={`${classes.control}`}
          type="number"
          placeholder="0000"
          id="custominput"
          name="code"
          // ref={inputRef}
          onChange={inputChangeHandler}
        />
      }
      btnOne={"Take me in"}
      btnOneAction={btnOneAction}
    />
    // </div>
  );
}

export default Codepopup;
