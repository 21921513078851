import React from 'react';
import CustomDialog from '../../reusable/CustomDialog';
import { delEmployee, getEmployees } from '../../../utils/ApiFunctions';

function DeleteEmployee({open,handleClose,id}) {
  const handleDelete=async()=>{
    const resp=await delEmployee(id);
    if(resp){
      window.location.reload();
    } 
  }
    return (
        <>
        
        <CustomDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        maincontent={"Are you sure you want to delete this employee?"}
        subcontent={"You are about to delete this employee. This won't stay in employee database any longer."}
        btnOne={"Delete"}
        btnTwo={"Cancel"}
        btnOneClick={handleDelete}
      />
        
        </>
    );
}

export default DeleteEmployee;