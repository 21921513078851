import React, { useEffect, useState } from "react";
import classes from "./ViewDetails.module.css";
import backicon from "../../images/back.svg";
import calliconblack from "../../images/calliconblack.svg";
import emailicon from "../../images/emailicon.svg";
import gold1img from "../../images/gold1img.png";
import calendaricon from "../../images/calendaricon.svg";
import timeicon from "../../images/timeicon.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ViewDetails = ({ link, eventDetails,remark,madefor }) => {
  const navigate=useNavigate();
  const [daytime, setDayTime] = useState("");
  useEffect(() => {
    setDayTime((prev) => ({
      day: moment(eventDetails?.data?.date).format("dddd"),
      monthYear: moment(eventDetails?.data?.date).format("DD MMMM YYYY"),
      time: `${moment(eventDetails?.data?.fromTime).format("HH:mm")}-${moment(
        eventDetails?.data?.toTime
      ).format("HH:mm")}`,
    }));
  }, [eventDetails]);
  return (
    <div className={classes.widthfifty}>
      <div className={classes.titlecontainer}>
        <div onClick={()=>navigate(-1)}>
          <div className={classes.back}>
            <img src={backicon} alt="" />
          </div>
        </div>
        <div className={classes.eventdetailscontainer}>
          <h2 className={classes.title}>{eventDetails?.data?.eventName}</h2>
          <div className={classes.detailscontainer}>
            <p>
              <img src={calendaricon} alt="" className={classes.iconstop} />
              {daytime.monthYear}
            </p>
            <p>
              <img src={timeicon} alt="" className={classes.iconstop} />
              {daytime.time}
            </p>
            <p>
              <img src={calendaricon} alt="" className={classes.iconstop} />
              {daytime.day}
            </p>
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.moredetailscontainer}>
          <div className={classes.header}>
            <div className={classes.signed}>
              <p>{eventDetails?.data?.status}</p>
            </div>
            <div className={classes.provided}>
              {eventDetails?.data?.needPhotographer && <p>Photgraphy provided</p>}
              {eventDetails?.data?.food && <p>Food provided</p>}
            </div>
          </div>
          <div className={classes.extradetailscontainer}>
            <div className={classes.assigned}>
              <p className={classes.assignedvalue}>
                {eventDetails?.data?.contactPerson}
              </p>
              <p className={classes.assignedetails}>
                <img src={calliconblack} alt="" className={classes.icons} />
                {eventDetails?.data?.contactNumber}
              </p>
              <p className={classes.assignedetails}>
                <img src={emailicon} alt="" className={classes.icons} />
                {eventDetails?.data?.contactEmail}
              </p>
            </div>
            <div className={classes.formattype}>
              <div className={classes.format}>
                <p className={classes.titlelike}>Format</p>
                <p className={classes.valuelike}>
                  {eventDetails?.data?.eventFormat}
                </p>
              </div>
              <div className={classes.type}>
                <p className={classes.titlelike}>Type</p>
                <p className={classes.valuelike}>{eventDetails?.data?.type}</p>
              </div>
            </div>
            <div className={classes.participantpayment}>
              <div className={classes.participant}>
                <p className={classes.titlelike}>Participant Requirements</p>
                <p className={classes.valuelike}>
                  {eventDetails?.data?.gender} & {eventDetails?.data?.ageGroup}
                </p>
              </div>
              <div className={classes.payment}>
                <p className={classes.titlelike}>Payment</p>
                <p className={classes.valuelike}>{eventDetails?.data?.payment}</p>
              </div>
            </div>
            <div className={classes.amountavg}>
              <div className={classes.amount}>
                <p className={classes.titlelike}>Amount</p>
                <p className={classes.valuelike}>{eventDetails?.data?.amount}</p>
              </div>
              <div className={classes.avg}>
                <p className={classes.titlelike}>
                  Average Payment by Participants
                </p>
                <p className={classes.valuelike}>
                  {Math.ceil(
                    eventDetails?.data?.amount /
                      eventDetails?.data?.noOfParticipants
                  )}
                </p>
              </div>
            </div>
            <div className={classes.leadhours}>
              <div className={classes.lead}>
                <p className={classes.titlelike}>Lead</p>
                <p className={classes.valuelike}>{eventDetails?.data?.lead}</p>
              </div>
              <div className={classes.hours}>
                <p className={classes.titlelike}>Actual Hours</p>
                <p className={classes.valuelike}>
                  {eventDetails?.data?.hours} hrs
                </p>
              </div>
            </div>
            <div className={classes.participantpayment}>
            {eventDetails?.data?.techManager&&<div className={classes.participant}>
                <p className={classes.titlelike}>Tech Manager</p>
                <p className={classes.valuelike}>
                  {eventDetails?.data?.techManager}
                </p>
              </div>}
              {eventDetails?.data?.eventManager&&<div className={classes.eventManager}>
                <p className={classes.titlelike}>Event Manager</p>
                <p className={classes.valuelike}>{eventDetails?.data?.eventManager}</p>
              </div>}
            </div>
            <div className={classes.foodcontainer}>
              <ul>
                {eventDetails?.data?.food.map(f=>(
                          <li>{f}</li>
                      ))}
              </ul>
            </div>
            {remark&&<div className={classes.eventManager}>
                <p className={classes.titlelike}>{`${madefor} Remark`}</p>
                <p className={classes.valuelike}>{remark}</p>
              </div>}
          </div>
          <div className={classes.courtscontainer}>
            {eventDetails?.data?.court.map((c) => (
              <div className={classes.courts}>
                <p className={classes.courtnum}>
                  <img src={gold1img} alt="" className={classes.courtimgs} />
                  {c.noOfWorkers}
                </p>
                <p className={classes.courtname}>{c.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>    
  );
};

export default ViewDetails;
