import React from "react";
import classes from "./EventButtons.module.css";
import { useNavigate } from "react-router-dom";
import downloadicon from "../../../images/downloadicon.svg";
import exportToExcel from "../../reusable/exportToExcel";
import { useSelector } from "react-redux";

function EventButtons(props) {
  const navigate = useNavigate();
  const alldata = useSelector((state) => state.event.allEvents);
  const handleDownload = () => {
    exportToExcel(alldata, "alldata.xlsx");
  };
  return (
    <div className={classes.buttons}>
      <button className={classes.download} onClick={handleDownload}>
        <img src={downloadicon} alt="" />
        <p>Download Excel</p>
      </button>
      <button
        className={classes.create}
        onClick={() => navigate("/create-event")}
      >
        Create New Event
      </button>
    </div>
  );
}

export default EventButtons;
