import React from 'react';
import CustomDialog from '../../reusable/CustomDialog';
import { updateEvent, updateRegistration } from '../../../utils/ApiFunctions';

function DeclinePopup({open,handleClose,regid,data,update,setUpdate,setOptionsOpen,setassignOptionsOpen}) {
  const transportationStatus = 
  data.transportationStatus === "Approved" || data.transportationStatus === "Declined"
    ? false
    : undefined;

  const handleDecline=async()=>{
    const resp=await updateRegistration(regid,{
      registrationStatus:false,
      transportationStatus
    });
    if(resp){
      // stateProp(true);
      const updateEventResp=await updateEvent(data?.eventid,{
        techManager: data?.techManager===data?.name ? null : data.techManager,
      eventManager: data?.eventManager===data?.name ? null : data.eventManager,
      })
      setOptionsOpen&&setOptionsOpen(false);
      setassignOptionsOpen&&setassignOptionsOpen(false);
      updateEventResp&&setUpdate(!update);
      handleClose();
    }
  }
    return (
        <>
        <CustomDialog
        open={open}
        handleClose={handleClose}
        title={"Decline"}
        maincontent={"Are you sure you want to decline the request?"}
        subcontent={"Declining this request would not allow the employee to participate."}
        btnOne={"Decline"}
        btnTwo={"Cancel"}
        btnOneClick={handleDecline}
      />
        </>
    );
}

export default DeclinePopup;