import React, { useState } from "react";
import classes from "./EmployeeHeader.module.css";
import OpenEvents from "./OpenEvents";
import RegisteredEvent from "./RegisteredEvent";
import { useLocation } from "react-router-dom";

function EmployeeHeader(props) {
  const location = useLocation();
  const userName = location.state?.userName || ""; // Fallback if name isn't passed
    const [activeLinks, setActiveLinks] = useState("open");
  return (
    <>
      <div className={classes.tabheader}>
        <div className={classes.eventcal}>
          <p className={classes.active}>Employee - {userName}</p>
        </div>
      </div>
      
        <div className={classes.linkcontainer}>
        <span
          className={` ${activeLinks === "open" ? classes.active : ""} ${
            classes.links
          }`}
          onClick={() => setActiveLinks((prev)=>"open")}
        >
          Open Events
        </span>
        <span
          className={`${classes.links} ${
            activeLinks === "registered" ? classes.active : ""
          }`}
          onClick={() => setActiveLinks((prev)=>"registered")}
        >
          Approved Events
        </span>
      </div>
      {activeLinks==="open" && <OpenEvents/>}
      {activeLinks==="registered" && <RegisteredEvent/>}
    </>
  );
}

export default EmployeeHeader;
