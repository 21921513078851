import React, { useEffect, useState } from 'react';
import CustomEvent from '../../reusable/CustomEvent';
import { useParams } from 'react-router-dom';
import { getOneEvent } from '../../../utils/ApiFunctions';
import Loader from '../../reusable/Loader';

function EditEvent(props) {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [initialData,setInitialData]=useState();
    const getEvent=async(id)=>{
        const resp=await getOneEvent(id);
        if(resp){
            setInitialData(resp.data.data);
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(()=>{
        getEvent(id);
    },[]);

    return (
       <>
       {loading && <Loader/>}
       {!loading&&initialData&&<CustomEvent title={"Edit Event"} initialData={initialData}/>}
       </>
    );
}

export default EditEvent;