import React, { useEffect, useState } from "react";
import DialogCard from "../../reusable/DialogCard";
import CustomCheckbox from "../../reusable/CustomCheckbox";
import MyFormControl from "../../reusable/MyFormControl";
import { sendEmail, updateRegistration } from "../../../utils/ApiFunctions";

function Approvepopup({ open, handleClose,courtData,regid,transportation,email,name,update,setUpdate,setOptionsOpen,setassignOptionsOpen}) {
  const [court,setCourt]=useState("");
  const [form ,setForm]=useState("");
  const [check,setCheck]=useState(transportation==="Requested"?false:undefined);
   const handleClick=async()=> {
    const resp=await updateRegistration(regid,form);
    if (!court) {
      alert("Please select a court before proceeding.");
      return;
    }
    if(resp){
      setOptionsOpen(false);
      setassignOptionsOpen(false);
      setUpdate(!update);
    }
    handleClose();
  }
  useEffect(()=>{
    setForm((prev)=>({
      ...prev,
      court:courtData.find(c=>c.name===court),
      transportationStatus:check,
      registrationStatus:true
    }))
  },[check,court]);

  return (
    <>
      <DialogCard
        
        open={open}
        handleClose={handleClose}
        title={name}
        content={
          <>
            {/* <CustomDropdown /> */}
            <MyFormControl title={"Court"} data={courtData?.map(c=>c.name)} value={court} setValue={setCourt}/>
            {transportation==="Requested"&& <CustomCheckbox text={"Allow transportation service"} setCheck={setCheck}/>}
          </>
        }
        btnOne={"Save"}
        btnTwo={"Cancel"}
        btnOneAction={handleClick}
      />
    </>
  );
}

export default Approvepopup;
