import React, { useEffect, useState } from "react";
import CustomEventList from "../../reusable/CustomEventList";
import { getFinishedEvents } from "../../../utils/ApiFunctions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { linkActions } from "../../store";
import Loader from "../../reusable/Loader";
import NoData from "../../reusable/NoData";

function Finished(props) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const dispatch = useDispatch();

  const getAllEvents = async () => {
    const resp = await getFinishedEvents();
    if (resp) {
      setTotalPages(resp.totalPages);
      const temp = resp.data.events?.map((event) => ({
        _id: event._id,
        name: event.eventName,
        date: moment(event.date).format("YYYY-MM-DD"),
        time: `${moment(event.fromTime).format("HH:mm")}-${moment(
          event.toTime
        ).format("HH:mm")}`,
        assignedTo: event.contactPerson,
        employees: event.noOfParticipants,
        format: event.eventFormat,
        court: event.court.map((c) => `${c.name}`).join(", "),
        status : event.status,
        paymentStatus: event.paymentStatus,
      }));
      setEvents(temp);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllEvents();
    dispatch(linkActions.setTo("/admin/eventheader/finished"));
  }, [page]);

  return (
    <>
      {loading && <Loader />}
      {!loading && !events[0] &&
        <NoData title={"No Finished Events"} subtitle={"Wait for the events to finish."}/>
      }
      {!loading && events[0]&&(
        <CustomEventList
          responsive={'tableTabletAdminEvents'}
          events={events}
          page={page}
          setPage={setPage}
          eventstate={"finished"}
          totalPages={totalPages}
        />
      )}
    </>
  );
}

export default Finished;
