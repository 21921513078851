import React, { useEffect, useRef, useState } from "react";
import deleteicon from "../../../images/deleteicon.svg"
import editicon from "../../../images/editicon.svg";
import classes from "./EmployeeOptions.module.css";
import DeleteEmployee from "./DeleteEmployee";
import EditEmployee from "./EditEmployee";

function EmployeeOptions({id}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const boxRef = useRef(null);
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const [editOpen,setEditOpen]=useState(false);
  const handleEditOpen=()=>{setEditOpen(true)};
  const handleEditClose=()=>{setEditOpen(false)};
  const [optionsOpen,setOptionsOpen]=useState(true);
  const handleBackdropClick = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setOptionsOpen(false);
    }
  };
  useEffect(() => {
    if(!dialogOpen && !editOpen){
    document.addEventListener('click', handleBackdropClick, true);
    return () => {
      document.removeEventListener('click', handleBackdropClick, true);
    };
  }
  if(dialogOpen||editOpen){
    setOptionsOpen(false);
  }
  }, [dialogOpen,editOpen]);
  return (
    <>
    {optionsOpen&&<div className={classes.boxcontainer} ref={boxRef}>
      <div className={classes.box}>
        <div className={classes.btngrp}>
            
            <span className={classes.btn} onClick={handleEditOpen}>
                <img src={editicon} alt="View details icon" className={classes.icon}/>
                <p className={classes.two}>Edit</p>
            </span>
            <span className={classes.btn} onClick={handleOpen}>
                <img src={deleteicon} alt="Delete event icon" className={classes.icon} />
                <p className={classes.three}>Delete</p>
            </span>
        </div>
    </div>
    
    </div>}
    <DeleteEmployee open={dialogOpen} handleClose={handleClose} id={id}/>
    <EditEmployee open={editOpen} handleClose={handleEditClose} id={id}/>
    </>
  );
}

export default EmployeeOptions;
