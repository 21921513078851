import {createSlice,configureStore} from '@reduxjs/toolkit';

const initialAuthState={allEvents:{},upcomingEvents:{},finishedEvents:{}};

const eventSlice=createSlice({
    name:'event',
    initialState:initialAuthState,
    reducers:{
        setAllEvents(state,action){
            state.allEvents=action.payload;
        },
        setUpcomingEvents(state,action){
            state.upcomingEvents=action.payload;
        },
        setFinishedEvents(state,action){
            state.finishedEvents=action.payload;
        }
    }
});

export const eventActions=eventSlice.actions;

const initialLinkState={to:""};

const linkSlice=createSlice({
    name:'link',
    initialState:initialLinkState,
    reducers:{
        setTo(state,action){
            state.to=action.payload;
        },
    }
});

export const linkActions=linkSlice.actions;

const initialUserDataState={data:{}};
const userDataSlice=createSlice({
    name:"userData",
    initialState:initialUserDataState,
    reducers:{
        setUserData(state,action){
            state.data=action.payload;
        },
    },
})
export const userDataActions=userDataSlice.actions;

const store=configureStore({
    reducer:{event:eventSlice.reducer,link:linkSlice.reducer,userData:userDataSlice.reducer}
});

export default store;